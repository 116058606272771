
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Thumb = () => {

    const { file } = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "thumb.png" }) {
                    childImageSharp {
                        fixed {
                            ...GatsbyImageSharpFixed_withWebp_tracedSVG
                        }
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        `
    )
    return (
        <Img
            className={"image"}
            fixed={file.childImageSharp.fixed}
            fluid={file.childImageSharp.fluid}
        />
    )
}
export default Thumb