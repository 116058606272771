import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Thumb from '../components/thumb'
import PropTypes from "prop-types"

import Layout from "../components/layout"
//import Header from '../components/header'

import { login, isAuthenticated } from "../utils/auth"

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
// Create an object with the options that you want to use. The options are divided in 4 main objects. You don't need to define them all
const lightbox = {
    buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false,
    },
    thumbnails: {
        showThumbnails: false,
    },
};

class Product extends Component {
    render() {

        if( !isAuthenticated() ) {
            login()
            return(
                <Layout>
                    <div className="container"><p>Redirecting to login...</p></div>
                </Layout>
            )
        }

        const product = this.props.data.wordpressWpProduct

        return (
            <Layout>

                <div className="container print-none">
                    <Link to="/products/">&lsaquo; Torna a Prodotti</Link>
                </div>

                <div className={"product"}>

                    <div className={"container"}>

                        <div className={"imager"}>
                            {product.acf.product__image
                                ?
                                    <div className={"image"}>
                                        <Img
                                            //className={"image"}
                                            //fixed={product.acf.product__image.localFile.childImageSharp.fixed}
                                            fluid={product.acf.product__image.localFile.childImageSharp.fluid}
                                            //objectFit="cover"
                                            //objectPosition="50% 50%"
                                        />
                                    </div>
                                :
                                <Thumb />
                            }

                            {product.acf.product__gallery &&
                                <div className="gallery items">
                                    <SRLWrapper options={lightbox}>
                                    {/*console.log(product.acf.product__gallery)*/}
                                    {product.acf.product__gallery.map(
                                        ({localFile}, index) => (
                                            <div key={index} className="item">
                                                <a href={localFile.childImageSharp.fluid.src} className="glightbox" data-gallery="gallery1">
                                                    <img
                                                        src={localFile.childImageSharp.resize.src}
                                                        className="slick-image"
                                                    />
                                                </a>
                                            </div>
                                        )
                                    )}
                                    </SRLWrapper>
                                </div>
                            }

                        </div>

                        <div className={"content"}>
                            <h1 className={"title"}>{product.title}</h1>

                            <div className={"data"}>

                                <div className={"type"}>
                                    <h4 className={"title"}>Categorie</h4>
                                    <ul>
                                        {product.type.map((c, index) => (
                                            <li key={index}>{c.name}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={"material"}>
                                    <h4 className={"title"}>Materiali</h4>
                                    <ul>
                                        {product.material.map((c, index) => (
                                            <li key={index}>{c.name}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={"style"}>
                                    <h4 className={"title"}>Stile</h4>
                                    <ul>
                                        {product.style.map((c, index) => (
                                            <li key={index}>{c.name}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={"hole"}>
                                    <h4 className={"title"}>Fori</h4>
                                    <ul>
                                        {product.hole.map((c, index) => (
                                            <li key={index}>{c.name}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={"season"}>
                                    <h4 className={"title"}>Stagione</h4>
                                    <ul>
                                        {product.season.map((c, index) => (
                                            <li key={index}>{c.name}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className={"genre"}>
                                    <h4 className={"title"}>Genere</h4>
                                    <ul>
                                        {product.genre.map((c, index) => (
                                            <li key={index}>{c.name}</li>
                                        ))}
                                    </ul>
                                </div>

                            </div>

                            <div className={"description"}>
                                <h4 className={"title"}>Descrizione</h4>
                                <div className={"text"} dangerouslySetInnerHTML={{__html: product.acf.product__description}} />
                            </div>

                            <button className="print" onClick={() => window.print()}>Stampa</button>

                        </div>

                    </div>

                </div>
            </Layout>
        )
    }
}

Product.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default Product

export const ProductQuery = graphql`
    query($id: String!) {
        wordpressWpProduct(id: { eq: $id }) {
            id
            title
            acf {
                product__description
                product__image {
                    localFile {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
                product__gallery {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                            resize(width: 180, height: 180) {
                                src
                                width
                                height
                            }
                        }
                    }
                }
            }
            type {
                name
            }
            material {
                name
            }
            style {
                name
            }
            hole {
                name
            }
            season {
                name
            }
            genre {
                name
            }
        }
    }
`
